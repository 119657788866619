import React, { Fragment } from 'react';
import { Navigate, Outlet } from 'react-router';
import { useSelector } from 'react-redux';

const PrivateRoute = () => {
  const user = useSelector((state) => state.authReducer.user);

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return !!user ? <Outlet/> : <Navigate to='/login'/>;
};

export default PrivateRoute;

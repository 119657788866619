// firebase.js
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const firebaseConfig = {
  apiKey: "AIzaSyArXzDvLAaJ8_2GNkKkcljyTwb-9YojMt4",
  authDomain: "devconnect-stage.firebaseapp.com",
  projectId: "devconnect-stage",
  storageBucket: "devconnect-stage.appspot.com",
  messagingSenderId: "850285022846",
  appId: "1:850285022846:web:8266692f311e1c366a522f",
  measurementId: "G-M1NCGQH2TK"
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();

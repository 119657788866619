import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../store/actions/index';

const Home = () => {
  const users = useSelector((state) => state.reducer1.users);
  const loading = useSelector((state) => state.reducer1.loading);
  const dispatch = useDispatch();

  const handleButtonClick = () => {
    dispatch(loginUser('GITHUB'));
  };

  return (
      <div>
        {
          loading ? (
              <div>Loading...</div>
          ) : (
              <Fragment>
                {
                  users.map((user, index) => {
                    return (
                        <div key={index}>{user.name.first} {user.name.last}</div>
                    );
                  })
                }
                <button type='submit'
                        className='flex m-4 justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                        onClick={handleButtonClick}>Dispatch Action
                </button>
              </Fragment>
          )
        }
      </div>
  );
};

export default Home;
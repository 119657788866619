import { AUTHENTICATION_IN_PROGRESS, FETCH_CUSTOMERS, FETCH_CUSTOMERS_START_LOADER, LOGIN_USER, LOGIN_USER_ERROR, LOGOUT_USER, STOP_LOADER } from './types';

import { auth } from '../../firebase';
import { GithubAuthProvider, GoogleAuthProvider } from 'firebase/auth';

export const fetchData = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_CUSTOMERS_START_LOADER });

    try {
      const response = await fetch('https://randomuser.me/api/?results=10');
      const data = await response.json();

      dispatch({ type: FETCH_CUSTOMERS, payload: data.results });
    } catch (error) {
      // Ignore
    }
  };
};

export const loginUser = (provider = 'EMAIL', email, password) => async (dispatch) => {
  try {
    dispatch({ type: AUTHENTICATION_IN_PROGRESS });

    let response;
    if (provider === 'EMAIL') {
      response = await auth.signInWithEmailAndPassword(email, password);

      const {
        uid,
        email: userEmail
      } = response.user;

      return dispatch({ type: LOGIN_USER, payload: { uid, email: userEmail } });
    }

    // We providers either Google or Github Login.
    let authProvider = new GoogleAuthProvider();
    if (provider === 'GITHUB') {
      authProvider = new GithubAuthProvider();
      authProvider.addScope('repo');
    }

    response = await auth.signInWithPopup(authProvider);

    const {
      uid,
      email: userEmail
    } = response.user;

    dispatch({ type: LOGIN_USER, payload: { uid, email: userEmail } });
  } catch (error) {
    console.log(error);
    let message = error.message;
    if (error.message.indexOf('auth/popup-closed-by-user') > -1) {
      return dispatch({ type: STOP_LOADER });
    }

    if (error.message.indexOf('auth/user-not-found') > -1) {
      message = 'User not found';
    }

    dispatch({ type: LOGIN_USER_ERROR, payload: message });
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    await auth.signOut();

    dispatch({ type: LOGOUT_USER });
  } catch (error) {
    throw new Error(error.message);
  }
};
// Action Types
const ADD_USER = 'ADD_USER';

// Initial State
const initialState = {
  users: []
};

// Action Creator
export const addUser = (user) => ({ type: ADD_USER, payload: user });

// Reducer
const reducer2 = (state = initialState, action) => {
  switch (action.type) {
    case ADD_USER:
      return { ...state, users: [ ...state.users, action.payload ] };
    default:
      return state;
  }
};

export default reducer2;
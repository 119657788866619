import { FETCH_CUSTOMERS, FETCH_CUSTOMERS_START_LOADER } from '../actions/types';

// Initial State
const initialState = {
  users: [],
  loading: false,
  error: null
};

// Reducer
const reducer1 = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CUSTOMERS_START_LOADER:
      return {
        ...state,
        loading: true
      };
    case FETCH_CUSTOMERS:
      return {
        ...state,
        users: action.payload,
        loading: false,
        error: null
      };
    default:
      return state;
  }
};

export default reducer1;

import { AUTHENTICATION_IN_PROGRESS, LOGIN_USER, LOGIN_USER_ERROR, LOGOUT_USER, STOP_LOADER } from '../actions/types';

const initialState = {
  user: null,
  loading: false,
  error: null
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATION_IN_PROGRESS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case LOGIN_USER:
      return {
        ...state,
        user: action.payload,
        loading: false,
        error: null
      };
    case LOGIN_USER_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case STOP_LOADER:
      return {
        ...state,
        loading: false
      };
    case LOGOUT_USER:
      return {
        ...state,
        user: null,
        loading: false,
        error: null
      };
    default:
      return state;
  }
};

export default authReducer;

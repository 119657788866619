import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../store/actions';
import { auth } from '../../firebase';
import { LOGIN_USER } from '../../store/actions/types';
import { useNavigate } from 'react-router';
import { toast, ToastContainer } from 'react-toastify';
import { Loader } from '../../components/Loader';

export default function Login() {
  const error = useSelector((state) => state.authReducer.error);
  const loading = useSelector((state) => state.authReducer.loading);

  if (error) {
    toast.error(error);
  }

  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ pending, setPending ] = useState(true);
  const [ provider, setProvider ] = useState('EMAIL');

  const dispatch = useDispatch();
  const history = useNavigate();

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        const userData = {
          uid: user.uid,
          email: user.email
        };

        setPending(false);
        dispatch({ type: LOGIN_USER, payload: userData });
        history('/');
      } else {
        setPending(false);
        history('/login');
      }
    });
  }, []);

  if (pending) {
    return (
        <Fragment>
          <div className='absolute right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2 '>
            <Loader className='border-blue-400 border-8 h-32 w-32'/>
          </div>
        </Fragment>
    );
  }

  const handleButtonClick = (provider) => {
    setProvider(provider);

    if (provider !== 'EMAIL') {
      return dispatch(loginUser(provider));
    }

    const emailFormat = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    if (email && password && email.match(emailFormat)) {
      dispatch(loginUser('EMAIL', email, password));
    }
  };

  return (
      <>
        <ToastContainer autoClose={2000} position='top-right'/>
        <div className='flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8'>
          <div className='sm:mx-auto sm:w-full sm:max-w-sm'>
            <img
                className='mx-auto h-10 w-auto'
                src='https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600'
                alt='Your Company'
            />
            <h2 className='mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900'>
              Sign in to your account
            </h2>
          </div>

          <div className='mt-10 sm:mx-auto sm:w-full sm:max-w-md'>
            <div className='bg-white shadow-md rounded-lg px-8 py-6'>
              <form className='space-y-6'>
                <div>
                  <label htmlFor='email' className='block text-sm font-medium leading-6 text-gray-900'>
                    Email address
                  </label>
                  <div className='mt-2'>
                    <input
                        id='email'
                        name='email'
                        type='email'
                        onChange={(event) => setEmail(event.target.value)}
                        autoComplete='email'
                        required
                        className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                    />
                  </div>
                </div>

                <div>
                  <div className='flex items-center justify-between'>
                    <label htmlFor='password' className='block text-sm font-medium leading-6 text-gray-900'>
                      Password
                    </label>
                    <div className='text-sm'>
                      <a href='#' className='font-semibold text-indigo-600 hover:text-indigo-500'>
                        Forgot password?
                      </a>
                    </div>
                  </div>
                  <div className='mt-2'>
                    <input
                        id='password'
                        name='password'
                        type='password'
                        onChange={(event) => setPassword(event.target.value)}
                        autoComplete='current-password'
                        required
                        className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                    />
                  </div>
                </div>

                <div>
                  <button
                      className='mb-2 flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                      onClick={() => handleButtonClick('EMAIL')}>
                    {loading && provider === 'EMAIL' ? (
                        <Loader/>
                    ) : <div>Sign in</div>}
                  </button>
                </div>

                <div className='relative flex py-5 items-center !px-[30px]'>
                  <div className='flex-grow border-t border-gray-300'/>
                  <span className='flex-shrink mx-4'>Or continue with</span>
                  <div className='flex-grow border-t border-gray-300'/>
                </div>

                <div className='flex items-center justify-around !mt-[18px]'>
                  <button type='button'
                          className='min-w-[50%] flex align-center justify-center text-white bg-[#4285F4] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2'
                          onClick={() => handleButtonClick('GOOGLE')}>
                    {loading && provider === 'GOOGLE' ? (
                        <Loader/>
                    ) : (
                        <Fragment>
                          <svg className='w-4 h-4 mr-2 -ml-1'
                               aria-hidden='true'
                               focusable='false'
                               data-prefix='fab'
                               data-icon='google'
                               role='img'
                               xmlns='http://www.w3.org/2000/svg'
                               viewBox='0 0 488 512'>
                            <path fill='currentColor'
                                  d='M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z'/>
                          </svg>
                          Google
                        </Fragment>
                    )}
                  </button>
                  <button type='button'
                          className='min-w-[50%] flex align-center justify-center text-white bg-[#24292F] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2'
                          onClick={() => handleButtonClick('GITHUB')}>
                    {loading && provider === 'GITHUB' ? (
                        <Loader/>
                    ) : (
                        <Fragment>
                          <svg className='w-4 h-4 mr-2 -ml-1'
                               aria-hidden='true'
                               focusable='false'
                               data-prefix='fab'
                               data-icon='github'
                               role='img'
                               xmlns='http://www.w3.org/2000/svg'
                               viewBox='0 0 496 512'>
                            <path fill='currentColor'
                                  d='M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3 .3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5 .3-6.2 2.3zm44.2-1.7c-2.9 .7-4.9 2.6-4.6 4.9 .3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3 .7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3 .3 2.9 2.3 3.9 1.6 1 3.6 .7 4.3-.7 .7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3 .7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3 .7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z'/>
                          </svg>
                          Github
                        </Fragment>
                    )}
                  </button>
                </div>
              </form>
            </div>

            <p className='mt-10 text-center text-sm text-gray-500'>
              Not a member?{' '}
              <a href='#' className='font-semibold leading-6 text-indigo-600 hover:text-indigo-500'>
                Start a 14 day free trial
              </a>
            </p>
          </div>
        </div>
      </>
  );
}

// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Home, Layout } from './components';
import { Login } from './pages';
import PrivateRoute from './PrivateRoute';

const App = () => {
  return (
      <Router>
        <Routes>
          <Route element={<PrivateRoute/>}>
            <Route element={<Layout/>}>
              <Route index element={<Home/>}/>
            </Route>
          </Route>
          <Route path='/login' element={<Login/>}/>
        </Routes>
      </Router>
  );
};

export default App;

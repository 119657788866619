import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import thunk from 'redux-thunk';

import {
  reducer1,
  reducer2,
  authReducer
} from './reducers/index';

const rootReducer = combineReducers({
  reducer1,
  reducer2,
  authReducer
});

const store = configureStore({
  reducer: rootReducer,
  middleware: [ thunk ]
});

export default store;